import './App.css';
import Calculator from './Calculator';

function App() {
  return (
    <>
    <Calculator />
    </>
  );
}

export default App;
