import React, { useEffect, useState } from "react";
import { Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement,
} from "chart.js";
import { TiArrowBack } from "react-icons/ti";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement
);

export const options = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (elem) {
          return `  $ ${Number(elem.raw.toFixed(0)).toLocaleString()}`;
        },
      },
    },
    legend: {
      position: "top",
      labels: {
        labels: {
          font: {
            family: "'Inter', sans-serif"
          }
        }
      }
    },
    title: {
      display: true,
      text: "Projected 5-year ROI",
    },
  },
};

const Calculator = () => {
  const [page, setpage] = useState(1);
  const [profit, setProfit] = useState({});
  const [years, setYears] = useState({});

  const Calculate = () => {

    let fieldSizeAcres = document.getElementById("fieldSizeAcres");
    let fieldSizeHectares = fieldSizeAcres.value * 0.4047;
    document.getElementById("fieldSizeHectares").innerHTML = Math.round(fieldSizeHectares);

    let r2 = (fieldSizeAcres.value * 43560) ** 0.5;
    document.getElementById("r2").innerHTML = Math.round(r2);
    let r3 = r2 * 0.3048;
    document.getElementById("r3").innerHTML = Math.round(r3);
    let r5 = (fieldSizeHectares * 10000) / r3;
    document.getElementById("r5").innerHTML = Math.round(r5);
    let r4 = r5 / 0.3048;
    document.getElementById("r4").innerHTML = Math.round(r4);
    let r7 = (fieldSizeHectares * 10000) ** 0.5;
    document.getElementById("r7").innerHTML = Math.round(r7);
    let r6 = r7 / 1609.34;
    document.getElementById("r6").innerHTML = r6.toFixed(2);
    let sprayRateGallonsPerAcre = document.getElementById("sprayRateGallonsPerAcre");
    let r8 = (sprayRateGallonsPerAcre.value * 3.785) / 0.4047;
    document.getElementById("r8").innerHTML = Math.round(r8);

    let spraySpeedKts = document.getElementById("spraySpeedKts");
    let r10 = spraySpeedKts.value * 0.514444;
    document.getElementById("r10").innerHTML = Math.round(r10);
    document.getElementById("r11").innerHTML = spraySpeedKts.value;
    let r12 = spraySpeedKts.value * 0.514444;
    document.getElementById("r12").innerHTML = Math.round(r12);
    let payloadKg = document.getElementById("payloadKg");
    let r13 = payloadKg.value / 0.454;
    document.getElementById("r13").innerHTML = Math.round(r13);
    let r15 = document.getElementById("r14").innerHTML / (60 * 60);
    document.getElementById("r15").innerHTML = r15.toFixed(5);
    let r17 = document.getElementById("r16").innerHTML / 60;
    document.getElementById("r17").innerHTML = r17.toFixed(3);
    let swathWidthFeet = document.getElementById("swathWidthFeet");
    let r19 = swathWidthFeet.value * 0.3048;
    document.getElementById("r19").innerHTML = r19.toFixed(1);
    let spraySystemEmptyWeightLbs = document.getElementById("spraySystemEmptyWeightLbs");
    let r20 = spraySystemEmptyWeightLbs.value * 0.454;
    document.getElementById("r20").innerHTML = r20.toFixed(1);
    let effectiveCapacityLiters = payloadKg.value - r20;
    document.getElementById("effectiveCapacityLiters").innerHTML = effectiveCapacityLiters.toFixed(1);
    let effectiveCapacityGallons = effectiveCapacityLiters / 3.785;
    document.getElementById("effectiveCapacityGallons").innerHTML = effectiveCapacityGallons.toFixed(1);

    let ar2 = effectiveCapacityLiters / r8;
    document.querySelectorAll(".ar2").forEach((element) => {
      element.innerHTML = ar2.toFixed(1);
    });

    let ar1 = ar2 / 0.4047;
    document.querySelectorAll(".ar1").forEach((element) => {
      element.innerHTML = ar1.toFixed(1);
    });

    let ar4 = ar2 / ((r10 * r19 * 60 * 60) / 10000);
    document.querySelectorAll(".ar4").forEach((element) => {
      element.innerHTML = ar4.toFixed(3);
    });

    let ar3 = ar4 * 60;
    document.querySelectorAll(".ar3").forEach((element) => {
      element.innerHTML = ar3.toFixed(1);
    });

    let ar5 = r4 / swathWidthFeet.value;
    document.querySelectorAll(".ar5").forEach((element) => {
      element.innerHTML = ar5.toFixed(0);
    });

    let ar6 = 15 * ar5;
    document.querySelectorAll(".ar6").forEach((element) => {
      element.innerHTML = ar6.toFixed(1);
    });

    let ar7 = ar6 / (60 * 60);
    document.querySelectorAll(".ar7").forEach((element) => {
      element.innerHTML = ar7.toFixed(3);
    });

    document.querySelectorAll(".ar8").forEach((element) => {
      element.innerHTML = "4";
    });

    let ar9 = (4 * 15) / 60;
    document.querySelectorAll(".ar9").forEach((element) => {
      element.innerHTML = ar9.toFixed(1);
    });

    let ar10 = r7 / r12 / 60 + ar9;
    document.querySelectorAll(".ar10").forEach((element) => {
      element.innerHTML = ar10.toFixed(1);
    });

    let ar11 = ar10 / 60;
    document.querySelectorAll(".ar11").forEach((element) => {
      element.innerHTML = ar11.toFixed(3);
    });

    let ar13 = ar2 / (ar11 + r17 + ar4 + ar7);
    document.querySelectorAll(".ar13").forEach((element) => {
      element.innerHTML = ar13.toFixed(1);
    });

    let ar12 = ar13 / 0.4047;
    document.querySelectorAll(".ar12").forEach((element) => {
      element.innerHTML = ar12.toFixed(1);
    });

    let chemicalCostUsdPerAcre = document.getElementById("chemicalCostUsdPerAcre");
    let r9 = chemicalCostUsdPerAcre.value * ar12;
    document.getElementById("r9").innerHTML = Math.round(r9);

    let eir2 = document.getElementById("fuelCostUsdPerGallon").value * 15;
    document.getElementById("eir2").innerHTML = eir2.toFixed(2);

    let eir1 = eir2 / ar12;
    document.getElementById("eir1").innerHTML = eir1.toFixed(2);

    let eir3 = eir2 * document.getElementById("hoursPerYear").value;
    document.getElementById("eir3").innerHTML = Number(
      eir3.toFixed(2)
    ).toLocaleString();

    let eir4 = document.getElementById("pilotCostUsdPerAcre").value * ar12;
    document.getElementById("eir4").innerHTML = Number(
      eir4.toFixed(2)
    ).toLocaleString();

    let eir5 = eir4 * document.getElementById("hoursPerYear").value;
    document.getElementById("eir5").innerHTML = Number(
      eir5.toFixed(2)
    ).toLocaleString();

    let eir6 = document.getElementById("groundCrewCostUsdPerHour").value / ar12;
    document.getElementById("eir6").innerHTML = Number(
      eir6.toFixed(2)
    ).toLocaleString();

    let eir7 =
      document.getElementById("groundCrewCostUsdPerHour").value *
      document.getElementById("hoursPerYear").value;
    document.getElementById("eir7").innerHTML = Number(
      eir7.toFixed(2)
    ).toLocaleString();

    let eir8 = document.getElementById("maintenanceCostUsdPerHour").value / ar12;
    document.getElementById("eir8").innerHTML = Number(
      eir8.toFixed(2)
    ).toLocaleString();

    let eir9 =
      document.getElementById("maintenanceCostUsdPerHour").value *
      document.getElementById("hoursPerYear").value;
    document.getElementById("eir9").innerHTML = Number(
      eir9.toFixed(2)
    ).toLocaleString();

    let eir11 =
      document.getElementById("insuranceAnnualCostUsd").value /
      document.getElementById("hoursPerYear").value;
    document.getElementById("eir11").innerHTML = Number(
      eir11.toFixed(2)
    ).toLocaleString();
    let eir10 = eir11 / ar12;
    document.getElementById("eir10").innerHTML = Number(
      eir10.toFixed(2)
    ).toLocaleString();

    let eir13 =
      document.getElementById("hangarAnnualCostUsd").value /
      document.getElementById("hoursPerYear").value;
    document.getElementById("eir13").innerHTML = Number(
      eir13.toFixed(2)
    ).toLocaleString();

    let eir12 = eir13 / ar12;
    document.getElementById("eir12").innerHTML = Number(
      eir12.toFixed(2)
    ).toLocaleString();

    let eir15 =
      document.getElementById("overheadAnnualCostUsd").value /
      document.getElementById("hoursPerYear").value;
    document.getElementById("eir15").innerHTML = Number(
      eir15.toFixed(2)
    ).toLocaleString();

    let eir14 = eir15 / ar12;
    document.getElementById("eir14").innerHTML = Number(
      eir14.toFixed(2)
    ).toLocaleString();

    let eir17 =
      document.getElementById("truckLeaseAnnualCostUsd").value /
      document.getElementById("hoursPerYear").value;
    document.getElementById("eir17").innerHTML = Number(
      eir17.toFixed(2)
    ).toLocaleString();

    let eir16 = eir17 / ar12;
    document.getElementById("eir16").innerHTML = Number(
      eir16.toFixed(2)
    ).toLocaleString();

    let eir19 =
      document.getElementById("softwareAnnualCostUsd").value /
      document.getElementById("hoursPerYear").value;
    document.getElementById("eir19").innerHTML = Number(
      eir19.toFixed(2)
    ).toLocaleString();

    let eir18 = eir19 / ar12;
    document.getElementById("eir18").innerHTML = Number(
      eir18.toFixed(3)
    ).toLocaleString();

    let eir22 = document.getElementById("trailerCostUsd").value / 5;
    document.getElementById("eir22").innerHTML = Number(
      eir22.toFixed(2)
    ).toLocaleString();

    let eir21 = eir22 / document.getElementById("hoursPerYear").value;
    document.getElementById("eir21").innerHTML = Number(
      eir21.toFixed(2)
    ).toLocaleString();

    let eir20 = eir21 / ar12;
    document.getElementById("eir20").innerHTML = Number(
      eir20.toFixed(2)
    ).toLocaleString();

    let eir25 = document.getElementById("aircraftCostUsd").value / 5;
    document.getElementById("eir25").innerHTML = Number(
      eir25.toFixed(2)
    ).toLocaleString();

    let eir24 = eir25 / document.getElementById("hoursPerYear").value;
    document.getElementById("eir24").innerHTML = Number(
      eir24.toFixed(2)
    ).toLocaleString();

    let eir23 = eir24 / ar12;
    document.getElementById("eir23").innerHTML = Number(
      eir23.toFixed(2)
    ).toLocaleString();

    let eir28 = document.getElementById("spraySystemCostUsd").value / 5;
    document.getElementById("eir28").innerHTML = Number(
      eir28.toFixed(2)
    ).toLocaleString();

    let eir27 = eir28 / document.getElementById("hoursPerYear").value;
    document.getElementById("eir27").innerHTML = Number(
      eir27.toFixed(2)
    ).toLocaleString();

    let eir26 = eir27 / ar12;
    document.getElementById("eir26").innerHTML = Number(
      eir26.toFixed(2)
    ).toLocaleString();

    // let eir31 = document.getElementById("GcsCostUsd").value / 5;
    // document.getElementById("eir31").innerHTML = Number(
    //   eir31.toFixed(2)
    // ).toLocaleString();

    // let eir30 = eir31 / document.getElementById("hoursPerYear").value;
    // document.getElementById("eir30").innerHTML = Number(
    //   eir30.toFixed(2)
    // ).toLocaleString();

    // let eir29 = eir30 / ar12;
    // document.getElementById("eir29").innerHTML = Number(
    //   eir29.toFixed(2)
    // ).toLocaleString();

    let totalcost =
      Number(eir1) +
      Number(document.getElementById("pilotCostUsdPerAcre").value) +
      Number(eir6) +
      Number(eir8) +
      Number(eir10) +
      Number(eir12) +
      Number(eir14) +
      Number(eir16) +
      Number(eir18) +
      Number(eir20) +
      Number(eir23);

    // document.getElementById("eir32").innerHTML = Number(
    //   totalcost.toFixed(2)
    // ).toLocaleString();

    let total2 =
      Number(eir2) +
      Number(eir4) +
      Number(document.getElementById("groundCrewCostUsdPerHour").value) +
      Number(document.getElementById("maintenanceCostUsdPerHour").value) +
      Number(eir11) +
      Number(eir13) +
      Number(eir15) +
      Number(eir17) +
      Number(eir19) +
      Number(eir21) +
      Number(eir24);
    // document.getElementById("eir33").innerHTML = Number(
    //   total2.toFixed(2)
    // ).toLocaleString();

    let total3 =
      Number(eir3) +
      Number(eir5) +
      Number(eir7) +
      Number(eir9) +
      Number(document.getElementById("insuranceAnnualCostUsd").value) +
      Number(document.getElementById("hangarAnnualCostUsd").value) +
      Number(document.getElementById("overheadAnnualCostUsd").value) +
      Number(document.getElementById("truckLeaseAnnualCostUsd").value) +
      Number(document.getElementById("softwareAnnualCostUsd").value) +
      Number(eir22) +
      Number(eir25);
    // document.getElementById("eir34").innerHTML = Number(
    //   total3.toFixed(2)
    // ).toLocaleString();

    let total4 =
      Number(document.getElementById("trailerCostUsd").value) +
      Number(document.getElementById("aircraftCostUsd").value) +
      Number(document.getElementById("spraySystemCostUsd").value) +
      Number(document.getElementById("GcsCostUsd").value);

    document.getElementById("eir35").innerHTML = Number(
      total4.toFixed(2)
    ).toLocaleString();

    let er2 = ar12 * document.getElementById("priceChargedPerAcre").value;
    document.querySelectorAll(".er2").forEach((elem) => {
      elem.innerHTML = Number(er2.toFixed(2)).toLocaleString();
    });

    let er3 = er2 * document.getElementById("hoursPerYear").value;
    document.querySelectorAll(".er3").forEach((elem) => {
      elem.innerHTML = Number(er3.toFixed(2)).toLocaleString();
    });

    let er5 = er2 - total2;
    document.querySelectorAll(".er5").forEach((elem) => {
      elem.innerHTML = Number(er5.toFixed(2)).toLocaleString();
    });

    let er6 = er3 - total3;
    document.querySelectorAll(".er6").forEach((elem) => {
      elem.innerHTML = Number(er6.toFixed(2)).toLocaleString();
    });

    document.querySelectorAll(".er1").forEach((elem) => {
      elem.innerHTML = parseFloat(document.getElementById("priceChargedPerAcre").value).toFixed(2);
    });

    document.querySelectorAll(".er4").forEach((elem) => {
      elem.innerHTML = (
        document.getElementById("priceChargedPerAcre").value - totalcost
      ).toFixed(2);
    });

    let margin =
      document.querySelector(".er4").innerHTML /
      document.querySelector(".er1").innerHTML;
    document.querySelectorAll(".er7").forEach((elem) => {
      elem.innerHTML = (margin * 100).toFixed(2);
    });

    let ROI = er6 / total4;
    document.querySelectorAll(".er8").forEach((elem) => {
      elem.innerHTML = (ROI * 100).toFixed(2);
    });

    setYears([
      er3,
      er3 + er3,
      er3 + er3 + er3,
      er3 + er3 + er3 + er3,
      er3 + er3 + er3 + er3 + er3,
      er6,
      er6 + er6,
      er6 + er6 + er6,
      er6 + er6 + er6 + er6,
      er6 + er6 + er6 + er6 + er6,
    ]);
    setProfit({ profit: er6, cost: er3 - er6, revenue: er3 });
  };

  const options1 = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (elem) {
            return `  $ ${Number(elem.raw.toFixed(0)).toLocaleString()}`;
          },
        },
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Projected Profit Margin",
      },
    },
  };

  const data = {
    labels: ["Profit", "Cost"],
    datasets: [
      {
        label: "Profit Chart",
        data: [profit.profit, profit.cost],
        backgroundColor: ["#84b9e0", "#C0522D"],
        borderWidth: 0,
      },
    ],
  };

  const labels = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"];

  const data2 = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Profit",
        data: [years[5], years[6], years[7], years[8], years[9]],
        borderColor: "#84b9e0",
        backgroundColor: "rgba(53, 162, 235, 0.2)",
        tension: 0.2,
      },
      {
        fill: true,
        label: "Revenue",
        data: [years[0], years[1], years[2], years[3], years[4]],
        borderColor: "#666666",
        backgroundColor: "rgb(120 120 120 / 30%)",
        tension: 0.2,
      }
    ],
  };

  // console.log(data2);

  useEffect(() => {
    page === 2 && Calculate();
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, [page]);

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const setValuesForR44 = () => {
    document.getElementById("fieldSizeAcres").value = 100;
    document.getElementById("sprayRateGallonsPerAcre").value = 2;
    document.getElementById("chemicalCostUsdPerAcre").value = 50;
    document.getElementById("spraySpeedKts").value = 60;
    document.getElementById("payloadKg").value = 315;
    document.getElementById("swathWidthFeet").value = 60;
    document.getElementById("spraySystemEmptyWeightLbs").value = 186;
    document.getElementById("priceChargedPerAcre").value = 14;
    document.getElementById("hoursPerYear").value = 350;
    document.getElementById("fuelCostUsdPerGallon").value = 6.33;
    document.getElementById("pilotCostUsdPerAcre").value = 2.5;
    document.getElementById("groundCrewCostUsdPerHour").value = 40;
    document.getElementById("maintenanceCostUsdPerHour").value = 50;
    document.getElementById("insuranceAnnualCostUsd").value = 50000;
    document.getElementById("hangarAnnualCostUsd").value = 4200;
    document.getElementById("overheadAnnualCostUsd").value = 10000;
    document.getElementById("truckLeaseAnnualCostUsd").value = 4500;
    document.getElementById("softwareAnnualCostUsd").value = 0;
    document.getElementById("trailerCostUsd").value = 30000;
    document.getElementById("aircraftCostUsd").value = 500000;
    document.getElementById("spraySystemCostUsd").value = 50000;
    document.getElementById("GcsCostUsd").value = 0;

    scrollToBottom();
  };

  const setValuesForR550X = () => {

    // same as R44 except for the following:
    // payloadKg = 550 kg
    // utilization goes up to 500 hrs/year?
    // software subscription > 0
    // Aircraft capital cost $1M
    // GCS cost goes up to $150k
    // maintenance costs go up $20/hr to $70/hr (do to overhaul reserves for actuators)
    // fuel burn goes up do to engines working harder (not currently implemented)

    document.getElementById("fieldSizeAcres").value = 200;
    document.getElementById("sprayRateGallonsPerAcre").value = 2;
    document.getElementById("chemicalCostUsdPerAcre").value = 50;
    document.getElementById("spraySpeedKts").value = 60;
    document.getElementById("payloadKg").value = 550;
    document.getElementById("swathWidthFeet").value = 60;
    document.getElementById("spraySystemEmptyWeightLbs").value = 186;
    document.getElementById("priceChargedPerAcre").value = 14;
    document.getElementById("hoursPerYear").value = 500;
    document.getElementById("fuelCostUsdPerGallon").value = 6.33;
    document.getElementById("pilotCostUsdPerAcre").value = 2.5;
    document.getElementById("groundCrewCostUsdPerHour").value = 40;
    document.getElementById("maintenanceCostUsdPerHour").value = 70;
    document.getElementById("insuranceAnnualCostUsd").value = 50000;
    document.getElementById("hangarAnnualCostUsd").value = 4200;
    document.getElementById("overheadAnnualCostUsd").value = 10000;
    document.getElementById("truckLeaseAnnualCostUsd").value = 4500;
    document.getElementById("softwareAnnualCostUsd").value = 80000;
    document.getElementById("trailerCostUsd").value = 30000;
    document.getElementById("aircraftCostUsd").value = 1000000;
    document.getElementById("spraySystemCostUsd").value = 50000;
    document.getElementById("GcsCostUsd").value = 150000;

    scrollToBottom();
  };

  return (
    <div className="calculator">

      <div style={{ display: page === 2 ? "none" : "block" }}>
        <h1 className="text-center fw-bold">
          Helicopter Aerial Application ROI Calculator
        </h1>
        <p className="text-center mb-5 subtitle">An open-source calculator from
          {" "}<a href="https://rotor.ai" rel="noopener" target="_blank">Rotor</a></p>
        <div className="d-flex justify-content-center align-items-center my-3">
          <img src="render.png" width="350" />
        </div>
        <hr />

        <p className="text-center mb-3">This calculator is meant to help you calculate the ROI of using various aircraft in agricultural aerial application missions.</p>
        <p className="text-center mb-3">You can input your own values in the form, or prefill the values for an aircraft. Then, click the button at the bottom of the form.</p>
        <div className="d-flex justify-content-center align-items-center my-3">
          <span className="me-3">Prefill values for:</span>
          <button className="btn btn-dark me-2" onClick={setValuesForR44}>
            R44
          </button>
          <button className="btn btn-primary" onClick={setValuesForR550X}>
            Rotor R550X
          </button>
        </div>
        <div className="card mt-3">
          <div className="header py-2 px-3 fs-5">Farm Variables</div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Field size</p>
            </div>
            <div className="column text-end">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="fieldSizeAcres"
                  onInput={Calculate}
                />
                <span className="ms-2">acres</span>
              </div>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="fieldSizeHectares"></p>
              <span className="me-3">hectares</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Field length</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="r2"></p>
              <span className="me-3">ft</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r3"></p>
              <span className="me-3">m</span>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Field width</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="r4"></p>
              <span className="me-3">ft</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r5"></p>
              <span className="me-3">m</span>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Transit distance</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="r6">
                {" "}
              </p>
              <span className="me-3">miles</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r7">
                {" "}
              </p>
              <span className="me-3">m</span>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="header py-2 px-3 fs-5">Chemical Variables</div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Spray rate</p>
            </div>
            <div className="column text-end">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="sprayRateGallonsPerAcre"
                  onInput={Calculate}
                />
                <span className="ms-2">gal/acre</span>
              </div>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r8"></p>
              <span className="me-3">L/hectare</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Chemical cost</p>
            </div>
            <div className="column text-end">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="chemicalCostUsdPerAcre"
                  onInput={Calculate}
                />
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r9"></p>
              <span className="me-3">$/hr</span>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="header py-2 px-3 fs-5">Aircraft Variables</div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Aircraft speed while spraying</p>
            </div>
            <div className="column text-end">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="spraySpeedKts"
                  onInput={Calculate}
                />
                <span className="ms-2">knots</span>
              </div>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r10"></p>
              <span className="me-3">m/s</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Transit speed</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="r11"></p>
              <span className="ms-2">knots</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r12"></p>
              <span className="me-3">m/s</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Aircraft payload</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="r13"></p>
              <span className="me-3">lbs</span>
            </div>
            <div className="column text-end">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="payloadKg"
                  onInput={Calculate}
                />
                <span className="ms-2">kg</span>
              </div>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Turn time</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="r14">
                15
              </p>
              <span className="ms-2">seconds</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r15"></p>
              <span className="me-3">hr</span>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Refill time</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="r16">
                1.5
              </p>
              <span className="ms-2">minutes</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r17"></p>
              <span className="me-3">hr</span>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Fuel burn per hour</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="r18">
                15
              </p>
              <span className="ms-2">gal/hr</span>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="header py-2 px-3 fs-5">Spray System Variables</div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Swath width</p>
            </div>
            <div className="column text-end">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="swathWidthFeet"
                  onInput={Calculate}
                />
                <span className="ms-2">ft</span>
              </div>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r19"></p>
              <span className="me-3">m</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Spray system empty weight</p>
            </div>
            <div className="column text-end">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="spraySystemEmptyWeightLbs"
                  onInput={Calculate}
                />
                <span className="ms-2">lbs</span>
              </div>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="r20"></p>
              <span className="me-3">kg</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center ">
            <div className="column">
              <p className="px-3 py-1 label">Effective capacity</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1" id="effectiveCapacityGallons"></p>
              <span className="ms-2">gal</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1" id="effectiveCapacityLiters"></p>
              <span className="me-3">L</span>
            </div>
          </div>
        </div>

        <div className="card mt-3 hidden">
          <div className="header py-2 px-3 fs-5">Aircraft Results</div>
          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Acreage per refill</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1 ar1"></p>
              <span className="ms-2">acres</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 ar2"></p>
              <span className="me-3">hectare</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Spray time between refill</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1 ar3"></p>
              <span className="ms-2">minutes</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 ar4"></p>
              <span className="me-3">hr</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Swath number</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1 ar5"></p>
              <span className="ms-2"></span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Total turn time (spraying)</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1 ar6"></p>
              <span className="ms-2">seconds</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 ar7"></p>
              <span className="me-3">hr</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Transit turns</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1 ar8"></p>
              <span className="ms-2"></span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 ar9"></p>
              <span className="me-3">minutes</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Transit time</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1 ar10"></p>
              <span className="ms-2">minutes</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 ar11"></p>
              <span className="me-3">hr</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Acreage per hour</p>
            </div>
            <div className="d-flex align-items-center column">
              <p className="px-3 pe-2 py-1 ar12"></p>
              <span className="ms-2">acre/hr</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 ar13"></p>
              <span className="me-3">hectare/hr</span>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="header py-2 px-3 fs-5 d-flex mb-1 align-items-center">
            <div className="column2 label">
              <p className="pr-3 fs-5">Economic Inputs</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2"></p>
                <span className="ms-2 fs-6">Cost per acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2"></p>
                <span className="ms-2 fs-6">Cost per hr</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2"></p>
                <span className="ms-2 fs-6">Cost per year</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2"></p>
                <span className="ms-2 fs-6">Purchase cost</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Price per acre</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="priceChargedPerAcre"
                  onInput={Calculate}
                />
                <span className="ms-2">$/acre</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Hours per year</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control"
                  placeholder="-"
                  id="hoursPerYear"
                  onInput={Calculate}
                />
                <span className="ms-2">hr</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Fuel cost</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control me-2"
                  placeholder="-"
                  id="fuelCostUsdPerGallon"
                  onInput={Calculate}
                />
                <span className="ms-2">$/gal</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Fuel burnt</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir1"></p>
                <span className="ms-2">$/gal</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir2"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir3"></p>
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Pilot cost</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control me-2"
                  placeholder="-"
                  id="pilotCostUsdPerAcre"
                  onInput={Calculate}
                />
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir4"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir5"></p>
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Ground crew</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir6"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="groundCrewCostUsdPerHour"
                  onInput={Calculate}
                />
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir7"></p>
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Maintenance</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir8"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="maintenanceCostUsdPerHour"
                  onInput={Calculate}
                />
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir9"></p>
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Aircraft Insurance</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir10"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir11"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="insuranceAnnualCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Hangar</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir12"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir13"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="hangarAnnualCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Misc. Overhead</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir14"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir15"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="overheadAnnualCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Truck lease</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir16"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir17"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="truckLeaseAnnualCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Software</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir18"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir19"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="softwareAnnualCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$/year</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Trailer cost</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir20"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir21"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir22"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="trailerCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Aircraft</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir23"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir24"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir25"></p>
                <span className="ms-2">$/year</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="aircraftCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Spray system</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir26"></p>
                <span className="ms-2">$/acre</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir27"></p>
                <span className="ms-2">$/hr</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir28"></p>
                <span className="ms-2">$/year</span>
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="spraySystemCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">GCS</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <input
                  type="number"
                  className="form-control ms-2"
                  placeholder="-"
                  id="GcsCostUsd"
                  onInput={Calculate}
                />
                <span className="ms-2">$</span>
              </div>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column2 label">
              <p className="px-3">Total</p>
            </div>
            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
              </div>
            </div>

            <div className="column2">
              <div className="d-flex align-items-center px-3 py-1">
                <p className="px-3 pe-2" id="eir35"></p>
                <span className="ms-2">$</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3 hidden">
          <div className="header py-2 px-3 fs-5">Economic Results</div>
          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Revenue</p>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 er1"></p>
              <span className="me-3">$/acre</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 er2"></p>
              <span className="me-3">$/hr</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 er3"></p>
              <span className="me-3">$/year</span>
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column">
              <p className="px-3 py-1 label">Profit</p>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 er4"></p>
              <span className="me-3">$/acre</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 er5"></p>
              <span className="me-3">$/hr</span>
            </div>
            <div className="d-flex align-items-center column justify-content-end">
              <p className="px-3 pe-2 py-1 er6"></p>
              <span className="me-3">$/year</span>
            </div>
          </div>
          <div className="d-flex my-1 align-items-center">
            <div className="column w-25">
              <p className="px-3 py-1 label">Margin</p>
            </div>
            <div className="d-flex align-items-center column w-25 justify-content-end">
              <p className="px-3 pe-2 py-1 er7"></p>%
            </div>
          </div>

          <div className="d-flex my-1 align-items-center">
            <div className="column w-25">
              <p className="px-3 py-1 label">ROI</p>
            </div>
            <div className="d-flex align-items-center column w-25 justify-content-end">
              <p className="px-3 pe-2 py-1 er8"></p>%
            </div>
          </div>
        </div>

        <button
          className="btn mt-3 btn-dark d-block mx-auto px-3 py-2"
          onClick={() => {
            setpage(2);
            Calculate();
          }}
        >
          Calculate ROI summary
        </button>
      </div>

      {
        page === 2 && (
          <div className="mt-3">
            <div
              style={{
                position: "absolute",
                top: "2%",
                left: "2%",
                cursor: "pointer",
              }}
              onClick={() => setpage(1)}
            >
              <TiArrowBack size={35} />
              <span className="ms-2">Back to calculator</span>
            </div>
            <h1 className="text-center fw-bold">ROI Summary</h1>
            <div className="d-flex justify-content-between wrapper align-items-start mb-5 flex-wrap">
              <div style={{ height: "350px" }} className="mt-3 chart">
                <Pie data={data} options={options1} />
              </div>
              <div style={{ height: "300px" }} className="mt-3 chart">
                {data.datasets && (
                  <Line
                    options={options}
                    data={data2}
                    style={{ height: "100%" }}
                  />
                )}
              </div>
            </div>

            <div className="card mt-3">
              <div className="header py-2 px-3 fs-5">Aircraft Results</div>
              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Acreage per refill</p>
                </div>
                <div className="d-flex align-items-center column">
                  <p className="px-3 pe-2 py-1 ar1"></p>
                  <span className="ms-2">acres</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 ar2"></p>
                  <span className="me-3">hectare</span>
                </div>
              </div>
              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Spray time between refill</p>
                </div>
                <div className="d-flex align-items-center column">
                  <p className="px-3 pe-2 py-1 ar3"></p>
                  <span className="ms-2">minutes</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 ar4"></p>
                  <span className="me-3">hr</span>
                </div>
              </div>
              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Swath number</p>
                </div>
                <div className="d-flex align-items-center column">
                  <p className="px-3 pe-2 py-1 ar5"></p>
                  <span className="ms-2"></span>
                </div>
              </div>
              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Total turn time (spraying)</p>
                </div>
                <div className="d-flex align-items-center column">
                  <p className="px-3 pe-2 py-1 ar6"></p>
                  <span className="ms-2">seconds</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 ar7"></p>
                  <span className="me-3">hr</span>
                </div>
              </div>
              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Transit turns</p>
                </div>
                <div className="d-flex align-items-center column">
                  <p className="px-3 pe-2 py-1 ar8"></p>
                  <span className="ms-2"></span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 ar9"></p>
                  <span className="me-3">minutes</span>
                </div>
              </div>
              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Transit time</p>
                </div>
                <div className="d-flex align-items-center column">
                  <p className="px-3 pe-2 py-1 ar10"></p>
                  <span className="ms-2">minutes</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 ar11"></p>
                  <span className="me-3">hr</span>
                </div>
              </div>
              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Acreage per hour</p>
                </div>
                <div className="d-flex align-items-center column">
                  <p className="px-3 pe-2 py-1 ar12"></p>
                  <span className="ms-2">acre/hr</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 ar13"></p>
                  <span className="me-3">hectare/hr</span>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="header py-2 px-3 fs-5">
                <div className="d-flex my-1 align-items-center">
                  <div className="column">
                    <p className="pr-3 fs-5">Economic Results</p>
                  </div>
                  <div className="d-flex align-items-center column justify-content-end">
                    <p className="px-3 pe-2 py-1"></p>
                    <span className="me-3 fs-6">Per acre</span>
                  </div>
                  <div className="d-flex align-items-center column justify-content-end">
                    <p className="px-3 pe-2 py-1"></p>
                    <span className="me-3 fs-6">Per hour</span>
                  </div>
                  <div className="d-flex align-items-center column justify-content-end">
                    <p className="px-3 pe-2 py-1"></p>
                    <span className="me-3 fs-6">Per year</span>
                  </div>
                </div>
              </div>




              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Revenue</p>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 er1"></p>
                  <span className="me-3">$/acre</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 er2"></p>
                  <span className="me-3">$/hr</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 er3"></p>
                  <span className="me-3">$/year</span>
                </div>
              </div>

              <div className="d-flex my-1 align-items-center">
                <div className="column">
                  <p className="px-3 py-1 label">Profit</p>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 er4"></p>
                  <span className="me-3">$/acre</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 er5"></p>
                  <span className="me-3">$/hr</span>
                </div>
                <div className="d-flex align-items-center column justify-content-end">
                  <p className="px-3 pe-2 py-1 er6"></p>
                  <span className="me-3">$/year</span>
                </div>
              </div>
              <div className="d-flex my-1 align-items-center">
                <div className="column w-25">
                  <p className="px-3 py-1 label">Margin</p>
                </div>
                <div className="d-flex align-items-center column w-25 justify-content-end">
                  <p className="px-3 pe-2 py-1 er7"></p>%
                </div>
              </div>

              <div className="d-flex my-1 align-items-center">
                <div className="column w-25">
                  <p className="px-3 py-1 label">ROI</p>
                </div>
                <div className="d-flex align-items-center column w-25 justify-content-end">
                  <p className="px-3 pe-2 py-1 er8"></p>%
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center my-3">
              <button className="btn btn-dark" onClick={() => setpage(1)}>
                Back to Calculator
              </button>
            </div>
          </div>
        )
      }
      <Footer />
    </div >
  );
};

const Footer = () => (
  <div className="footer">
    Made by <a href="https://rotor.ai">Rotor</a>. Please <a href="mailto:inquires@rotor.ai">let us know</a> if you find an error!
  </div>
);

export default Calculator;
